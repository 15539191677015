<template>
  <div class="modal-reset-password">
    <mkp-image :lazy-src="url" class="reset-password-img" />

    <h1 class="reset-password-title">{{ $t("forms.resetPassword.title") }}</h1>

    <component
      :is="componentLoader"
      :props="option"
      @Resetsubmit="modifStep($event)"
    />
    <mkp-link class="link-connexion" @click="changeStep('connection')">
      {{ $t("forms.resetPassword.form.links.connection") }}
    </mkp-link>
  </div>
</template>

<script>
import MkpImage from "../../../simple/image/mkp-image";
import MkpLink from "../../../simple/link/mkp-link";
import "./mkp-reset-password.scss";

export default {
  name: "MkpFormReset",
  components: { MkpImage, MkpLink },
  data: () => {
    return {
      option: {},
      url: require("./../../../../assets/images/sign-in.svg"),
      step: "form",
      steps: {
        form: () => import("./steps/mkp-form-reset"),
        validation: () => import("./steps/mkp-reset-password-validation")
      }
    };
  },
  computed: {
    componentLoader() {
      return this.steps[this.step];
    }
  },
  methods: {
    changeStep(step) {
      this.$store.dispatch("setModalStep", step);
    },
    modifStep(event) {
      /*this.$store
        .dispatch("sendRequestAccount", {
          _this: this,
          url:
            getValidBaseUrl() +
            process.env.VUE_APP_BASE_URL_API +
            "/users",
          body: {
            email: event.email
          }
        })
        .then(() => {
          this.option = {
            email: event.email
          };
          //TODO: I18N faire
          this.$notify({
            group: "form",
            type: "notification",
            title: "Récupération de mot de passe",
            text: "Demande envoyé"
          });
          this.step = "validation";
        })
        .catch(error => {
          console.log(error);
        });*/

      this.option = {
        email: event.email
      };
      this.step = "validation";
    }
  }
};
</script>

<style scoped></style>
